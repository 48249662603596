export default [

    // USER PROFILE
    {
        name: 'UserProfile',
        path: '/user-profile',
        component: () =>
            import('@/views/UserProfile'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // APPROVAL FLOW
    {
        name: 'ApprovalFlow',
        path: '/approval-flow',
        component: () =>
            import('@/views/ApprovalFlow'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowLevel',
        path: '/approval-flow-level',
        component: () =>
            import('@/views/ApprovalFlowLevel'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowTransaction',
        path: '/approval-flow-transaction',
        component: () =>
            import('@/views/ApprovalFlowTransaction'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    // {
    //     name: 'ApprovalFlowCostCenter',
    //     path: '/approval-flow-cost-center',
    //     component: () =>
    //         import('@/views/ApprovalFlowCostCenter'),
    //         meta: {
    //             requireAuth: true,
    //             redirectHomeIfLogged: false
    //         }
    // },
    // {
    //     name: 'PlanningGroupVersion',
    //     path: '/planning-group-version',
    //     component: () =>
    //         import('@/views/PlanningGroupVersion'),
    //         meta: {
    //             requireAuth: true,
    //             redirectHomeIfLogged: false
    //         }
    // },
    // {
    //     name: 'PlanningGroup',
    //     path: '/planning-group',
    //     component: () =>
    //         import('@/views/PlanningGroup'),
    //         meta: {
    //             requireAuth: true,
    //             redirectHomeIfLogged: false
    //         }
    // },
    {
        name: 'ApprovalFlowCostCenter',
        path: '/approval-flow-cost-center',
        component: () =>
            import('@/views/ApprovalFlowCostCenter'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventStatusCostCenter',
        path: '/event-status-cost-center',
        component: () =>
            import('@/views/EventStatusCostCenter'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // ACCOUNT
    {
        name: 'ChartAccountGroup',
        path: '/chart-account-group',
        component: () =>
            import('@/views/ChartAccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ChartAccountGroupVersion',
        path: '/chart-account-group-version',
        component: () =>
            import('@/views/ChartAccountGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroup',
        path: '/account-group',
        component: () =>
            import('@/views/AccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupCalc',
        path: '/account-group-calc',
        component: () =>
            import('@/views/AccountGroupCalc'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupVersion',
        path: '/account-group-version',
        component: () =>
            import('@/views/AccountGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupReference',
        path: '/account-group-reference',
        component: () =>
            import('@/views/AccountGroupReference'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupSystemId',
        path: '/account-group-system-id',
        component: () =>
            import('@/views/AccountGroupSystemId'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupFilterVersion',
        path: '/account-group-filter-version',
        component: () =>
            import('@/views/AccountGroupFilterVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupFilter',
        path: '/account-group-filter',
        component: () =>
            import('@/views/AccountGroupFilter'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // EVENT
    {
        name: 'Event',
        path: '/event',
        component: () =>
            import('@/views/Event'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventConfig',
        path: '/event-config',
        component: () =>
            import('@/views/EventConfig'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventConfigAccountGroup',
        path: '/event-config-account-group',
        component: () =>
            import('@/views/EventConfigAccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // PURPOSE
    {
        name: 'Propósitos',
        path: '/purpose',
        component: () =>
            import('@/views/Purpose'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'PurposeRatioType',
        path: '/purpose-ratio-type',
        component: () =>
            import('@/views/PurposeRatioType'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'PurposeCostCenter',
        path: '/purpose-cost-center',
        component: () =>
            import('@/views/PurposeCostCenter'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'Propósitos X Contas contábeis',
        path: '/purpose-account',
        component: () =>
            import('@/views/PurposeAccount'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // OPEX PLANNING
    {
        name: 'OpexPlanning',
        path: '/opex-planning',
        component: () =>
            import('@/views/OpexPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            },
        props: { idPlanningFunction: 6 }
    },
    {
        name: 'OpexMatrixPlanning',
        path: '/opex-matrix-planning',
        component: () =>
            import('@/views/OpexMatrixPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'OpexEmployeePlanning',
        path: '/opex-employee-planning',
        component: () =>
            import('@/views/OpexEmployeePlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'OpexEmployeeVacation',
        path: '/opex-employee-vacation',
        component: () =>
            import('@/views/OpexEmployeeVacation'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'SpecificExpenses',
        path: '/specific-expenses',
        component: () =>
            import('@/views/OpexPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            },
        props: { idPlanningFunction: 17 }
    },
    // {
    //     name: 'CustomerGroup',
    //     path: '/customer-group',
    //     component: () =>
    //         import('@/views/CustomerGroup'),
    //         meta: {
    //             requireAuth: true,
    //             redirectHomeIfLogged: false
    //         }
    // },
    {
        name: 'DetailedOpexPlanning',
        path: '/detailed-opex-planning',
        component: () =>
            import('@/views/DetailedOpexPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ReportGallery',
        path: '/report-gallery',
        component: () =>
            import('@/views/ReportGallery'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'OpexReport',
        path: '/opex-report',
        component: () =>
            import('@/views/OpexReport'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    }
]