const clients = [
    {
        id: 1,
        system: 'ZNAP',
        module: 'ZNAP_OPEX_PLANNING',
        modulePath: 'opex', // 'opex' || 'homol.opex'
        language: 'pt',
        port: 8086,
        theme: 'znap.js',
        env: 'prod' // prod, homol, localhost
    },
]

const getClientConfigs = (clientId) => clients.find(client => client.id === clientId)
const configs = getClientConfigs(1)

export default {
    ...configs
}
