import { render, staticRenderFns } from "./BgMenu.vue?vue&type=template&id=4e0c3ece&scoped=true&"
import script from "./BgMenu.vue?vue&type=script&lang=js&"
export * from "./BgMenu.vue?vue&type=script&lang=js&"
import style0 from "./BgMenu.vue?vue&type=style&index=0&id=4e0c3ece&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e0c3ece",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VMenu,VSheet,VTooltip})
