import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/axios'
import './plugins/vee'
import './plugins/vuetoastification'
import configs from './configs'

import { getPermissions, filters, calendar, errorFunction } from '@/utils'
Vue.filter('dateFilter', filters.dateFilter)
Vue.filter('floatFilter', filters.floatFilter)
Vue.filter('cpfCnpjFilter', filters.cpfCnpjFilter)
Vue.prototype.$calendar = calendar
Vue.prototype.$fnError = errorFunction

Vue.config.productionTip = false

Vue.prototype.$getPermissions = getPermissions

let urlprefix = configs.system.toLocaleLowerCase() + '.'
if(configs.system.toLocaleLowerCase() === 'znap') urlprefix = ''
let urlHomol = 'homol.'
if(configs.env.toLocaleLowerCase() === 'prod') urlHomol = ''

if(configs.env.toLocaleLowerCase() === 'localhost'){
    Vue.prototype.$ipUser = 'http://localhost:8000/'
    Vue.prototype.$ipClient = 'http://localhost:8001/'
    Vue.prototype.$ipSecurity = 'http://localhost:8002/'
    Vue.prototype.$ipSales = 'http://localhost:8012/'
    Vue.prototype.$ipSalesPlanning = 'http://localhost:8014/'
    Vue.prototype.$ipDynamicReport = 'http://localhost:8021/'
    Vue.prototype.$ipAccount = 'http://localhost:8005/'
    Vue.prototype.$ipEvent = 'http://localhost:8013/'
    Vue.prototype.$ipApprovalFlow = 'http://localhost:8006/'
    Vue.prototype.$ipOrganization = 'http://localhost:8004/'
    Vue.prototype.$ipUnit = 'http://localhost:8008/'
    Vue.prototype.$ipProduct = 'http://localhost:8009/'
    Vue.prototype.$ipComment = 'http://localhost:8015/'
    Vue.prototype.$ipCustomer = 'http://localhost:8010/'
    Vue.prototype.$ipOpexPlanning = 'http://localhost:8019/'
    Vue.prototype.$ipPlPlanning = 'http://localhost:8022/'
    Vue.prototype.$ipMessage = 'http://localhost:8003/'
} else {
    Vue.prototype.$ipUser = `https://${urlprefix}api.${urlHomol}user.znaptech.com/`
    Vue.prototype.$ipClient = `https://${urlprefix}api.${urlHomol}client.znaptech.com/`
    Vue.prototype.$ipSecurity = `https://${urlprefix}api.${urlHomol}security.znaptech.com/`
    Vue.prototype.$ipSales = `https://${urlprefix}api.${urlHomol}sales.area.znaptech.com/`
    Vue.prototype.$ipSalesPlanning = `https://${urlprefix}api.${urlHomol}sales.planning.znaptech.com/`
    Vue.prototype.$ipDynamicReport = `https://${urlprefix}api.${urlHomol}dynamic.report.znaptech.com/`
    Vue.prototype.$ipAccount = `https://${urlprefix}api.${urlHomol}account.znaptech.com/`
    Vue.prototype.$ipEvent = `https://${urlprefix}api.${urlHomol}event.znaptech.com/`
    Vue.prototype.$ipApprovalFlow = `https://${urlprefix}api.${urlHomol}approval.flow.znaptech.com/`
    Vue.prototype.$ipOrganization = `https://${urlprefix}api.${urlHomol}organization.znaptech.com/`
    Vue.prototype.$ipUnit = `https://${urlprefix}api.${urlHomol}unit.znaptech.com/`
    Vue.prototype.$ipProduct = `https://${urlprefix}api.${urlHomol}product.znaptech.com/`
    Vue.prototype.$ipComment = `https://${urlprefix}api.${urlHomol}comment.znaptech.com/`
    Vue.prototype.$ipCustomer = `https://${urlprefix}api.${urlHomol}customer.znaptech.com/`
    Vue.prototype.$ipOpexPlanning = `https://${urlprefix}api.${urlHomol}opex.planning.znaptech.com/`
    Vue.prototype.$ipPlPlanning = `https://${urlprefix}api.${urlHomol}pl.planning.znaptech.com/`
    Vue.prototype.$ipMessage = `https://${urlprefix}api.${urlHomol}message.znaptech.com/`
}

Vue.prototype.$vuetify = vuetify

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
